import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Link from 'next/link'
import {
    getPersistedState,
    setPersistedState,
} from '../../actions/persistedStateActions';
import { PERSISTED_STATE_SEARCH_PAGE } from '../../__constants__';

import withStyles from '@material-ui/core/styles/withStyles';


const propTypes = {
    value: PropTypes.string.isRequired,
    highlight: PropTypes.bool,
    url: PropTypes.string,
};

const styles = theme => ({
    anchor: {
        fontSize: theme.spacing(2),
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
    highlight: {
        color: theme.palette.primary.main,
        fontWeight: 600,
    },
});

class Breadcrumb extends Component {
    onClick = () => {
        const persistedState = getPersistedState(PERSISTED_STATE_SEARCH_PAGE);

        if (persistedState) {
            const newPersistedState = { ...persistedState };

            if (newPersistedState.searchType) {
                newPersistedState.searchType = 'location';

                setPersistedState(PERSISTED_STATE_SEARCH_PAGE, newPersistedState);
            }
        }
    }

    render() {
        const {
            value,
            classes,
            url,
            highlight,
        } = this.props;

        if (!url) {
            return (
                <span className={classes.anchor}>
                    <span
                        className={cn({
                            [classes.highlight]: highlight,
                        })}
                    >
                        {value}
                    </span>
                </span>
            );
        }

        return (
            <Link
                href={url}
                onClick={this.handleBreadcrumbClick}
            >
                <a className={classes.anchor}>
                    <span className={cn({
                        [classes.highlight]: highlight,
                    })}>
                        {value}
                    </span>
                </a>
            </Link>
        );
    }
}

Breadcrumb.propTypes = propTypes;

export default withStyles(styles)(Breadcrumb);
