import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link'
import Breadcrumbs from './Breadcrumbs';
import Breadcrumb from './Breadcrumb';
import { FINANCIAL_ADVISER_ID } from '../../__constants__';

import { getSimpleRole, getRoleUrl } from '../../data/getRole';
import withStyles from '@material-ui/core/styles/withStyles';
import { cityLinks } from '../Header/NavItem';
import { getNewUrlType } from '../../utils/urlHelpers';

const propTypes = {
    withBg: PropTypes.bool,
};

const defaultProps = {
    withBg: false,
};

const styles = theme => ({
    anchor: {
        fontSize: theme.spacing(2),
        textDecoration: 'none',
    },
});

const BreadcrumbsProfilePage = props => {
    const {
        classes,
        vertical_id,
        professionalName,
        variant,
        city,
        town,
        region
    } = props;
    const role = getSimpleRole(vertical_id);
    const urlPath = getRoleUrl(vertical_id);
    const cityIsTown = (city === town) && (city || town);
    const regionIsCity = region && city && (region.name === city);
    const regionLink = region ? cityLinks.find(cityLink => region.name == cityLink.label) : null

    return (
        <Breadcrumbs variant={variant}>
            <Link href={getNewUrlType(urlPath)}>
                <a className={classes.anchor}>{ role }</a>
            </Link>
            {
                !regionIsCity &&
                <Breadcrumb
                    url={`${urlPath}${regionLink.link}`}
                    value={region.name}
                    highlight={city && !town && !professionalName}
                />
            }
            {
                cityIsTown &&
                <Breadcrumb
                    url={`${urlPath}/${city.toLowerCase()}`}
                    value={city}
                    highlight={city && !town && !professionalName}
                />
            }
            {
                !cityIsTown && Boolean(city) &&
                <Breadcrumb
                    url={`${urlPath}/${city.toLowerCase()}`}
                    value={city}
                    highlight={city && !town && !professionalName}
                />
            }
            {
                !cityIsTown && Boolean(town) &&
                <Breadcrumb
                    url={vertical_id === FINANCIAL_ADVISER_ID ? `${urlPath}/${town.toLowerCase()}` : null}
                    value={town}
                    highlight={city && town && !professionalName}
                />
            }
            {
                professionalName &&
                <span className={classes.anchor}>
                    { professionalName }
                </span>
            }
        </Breadcrumbs>
    );
}

BreadcrumbsProfilePage.defaultProps = defaultProps;
BreadcrumbsProfilePage.propTypes = propTypes;

export default withStyles(styles)(BreadcrumbsProfilePage);
