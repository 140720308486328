import {
    PERSISTED_STATE_SEARCH_FILTERS,
    PERSISTED_STATE_SEARCH_LISTINGS,
    PERSISTED_STATE_SEARCH_PAGE,
} from '../__constants__';


export const setPersistedState = (key, values) => {
    if (process.browser) {
        sessionStorage.setItem(key, JSON.stringify(values));
    }
};

export const getPersistedState = key => {
    if (process.browser) {
        const persistedState = sessionStorage.getItem(key);
        return persistedState ? JSON.parse(persistedState) : undefined;
    }
};

export const clearPersistedSearchFilters = () => {
    sessionStorage.removeItem(PERSISTED_STATE_SEARCH_PAGE);
    sessionStorage.removeItem(PERSISTED_STATE_SEARCH_LISTINGS);
    sessionStorage.removeItem(PERSISTED_STATE_SEARCH_FILTERS);
};
