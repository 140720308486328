import React  from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const propTypes = {
    variant: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.string
    ])
};

const defaultProps = {
    variant: "default",
};

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
            paddingBottom: 0,
        },
        '& .MuiBreadcrumbs-li': {
            padding: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`
        }
    },
    blueBg: {
        background: theme.palette.secondary.main,
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,

        '& a, & span': {
            color: theme.palette.primary.main,
        },

        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    separatorDefault: {
        color: theme.palette.primary.main,
    },
    separatorWhite: {
        color: theme.palette.primary.contrastText,
    },
}));

const BreadcrumbsBase = ({ children, variant }) => {
    const classes = useStyles();

    return (
        <MuiBreadcrumbs 
            aria-label="breadcrumb"
            id="breadcrumbs"
            className={cn({
                [classes.root]: true,
                [classes.blueBg]: variant === 'blue',
            })}
            separator={
                <NavigateNextIcon 
                    fontSize="small"
                    className={cn({
                        [classes.separatorDefault]: variant === 'default' || !Boolean(variant),
                        [classes.separatorDefault]: variant === 'blue',
                    })}
                />
            }
        >
            { children }
        </MuiBreadcrumbs>
    );
};

BreadcrumbsBase.defaultProps = defaultProps;
BreadcrumbsBase.propTypes = propTypes;

export default BreadcrumbsBase;
