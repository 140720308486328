import React from 'react';
import { Skeleton as MuiSkeleton } from '@material-ui/lab';

export const mapEmptyArr = (length, fn) => Array.from({ length }, (_, i) => fn(i));

const Skeleton = (props) => {
    const {
        width,
        height,
        style = {},
        variant = 'rect',
        ...rest
    } = props;

    return(
        <MuiSkeleton
            {...rest}
            animation="pulse"
            width="100%"
            height={height}
            variant={variant}
            style={{ maxWidth: width, ...style }}
        />
    );
};

export default Skeleton;
