import React, { Component, Fragment } from 'react';
import _ from 'lodash';
import ProfileClientReview from './ProfileClientReview';
import ProfileClientReviewsEmpty from './ProfileClientReviewsEmpty';
import ReviewPagination from '../ReviewPagination/ReviewPagination';
import ProfileClientReviewsTitle from './ProfileClientReviewsTitle';
import LoadingSpinner from '../LoadingSpinner';
import { formatPlural, getResultsThresholdCount } from '../../utils/utilities';
import ProfileClientReviewsLabel from "./ProfileClientReviewsLabel";
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '../CustomButton';
import withStyles from '@material-ui/core/styles/withStyles';
import Link from 'next/link'


const defaultProps = {
    showReadMoreButton: true,
    loading: false,
    reviewsOffset: 0,
    profileName: '',
    handleShowMoreReviews: () => {},
    pagination: {},
};

const styles = theme => ({
    container: {
        background: '#F4FBFC',
        padding: theme.spacing(4),

        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
            marginTop: theme.spacing(4),
            overflow: 'hidden',
        },
    },
    root: {
        background: theme.palette.primary.contrastText,
        padding: theme.spacing(4),
        marginBottom: theme.spacing(4),

        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(2),
        },

        [theme.breakpoints.down('sm')]: {
            margin: `0 ${theme.spacing(2)}px ${theme.spacing(2)}px`,
        },
    },
    loadingWrapper: {
        color: theme.palette.primary.contrastText,
    },
    actions: {
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            margin: `0 ${theme.spacing(2)}px`,
        },
    },
    actionButtonFirst: {
        marginBottom: theme.spacing(1),

        [theme.breakpoints.down('sm')]: {
            marginBottom: theme.spacing(2),
        },
    },
    actionButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
});

class ProfileClientReviews extends Component {
    renderReview = (review, i) => {
        const data = _.get(this.props, ['profileData'], null);
        const { full_name, first_name, vertical } = data;

        return (
            <ProfileClientReview
                key={i}
                review={review}
                type={review.reviewer_type}
                professionalName={full_name}
                professionalFirstName={first_name}
                professionalVertical={vertical}
            />
        );
    }

    renderReviewList = () => {
        const {
            classes,
            handleShowMoreReviews,
            reviewsOffset,
            reviewsTotalCount,
            reviews,
            showReadMoreButton,
            profileData,
            pagination,
        } = this.props;

        const nextResultsCount = getResultsThresholdCount(reviewsTotalCount, reviewsOffset, 5);

        return (
            <Fragment>
                {
                    _.isEmpty(reviews)
                        ? <Paper className={classes.root}>
                            <Typography>
                                No reviews matching your filter selections.
                            </Typography>
                        </Paper>
                        : reviews.map((review, i) => this.renderReview(review, i))
                }
                {
                    showReadMoreButton &&
                    <div className={classes.actions}>
                        {
                            0 < nextResultsCount &&
                            <div className={classes.actionButtonFirst}>
                                <Button
                                    className={classes.actionButton}
                                    onClick={handleShowMoreReviews}
                                    color="tertiary"
                                    variant="contained"
                                >
                                    {`Read ${nextResultsCount} more ${formatPlural(reviews.length, 'Review', 'Reviews')}`}
                                </Button>
                            </div>
                        }
                        {
                            profileData &&
                            <div>
                                <Link href={ `/${this.props.profileData.permalink.url}/reviews`} passHref>
                                    <Button
                                        className={classes.actionButton}
                                        color="tertiary"
                                        variant="contained"
                                    >
                                        Read all reviews
                                    </Button>
                                </Link>
                            </div>
                        }
                    </div>
                }
                {
                    !_.isEmpty(pagination) &&
                    <div className={classes.actions}>
                        <ReviewPagination
                            baseUrl={pagination.baseUrl}
                            pagesCount={pagination.pagesCount}
                        />
                    </div>
                }
            </Fragment>
        );
    }

    render() {
        const {
            classes,
            profileData,
            loading,
            profileName,
            reviewsTotalCount,
        } = this.props;
        const profileReviewCount = _.get(profileData, ['review_count'], null);

        const hasReviewsFromOtherFirms = _.get(profileData, 'professional_key_attributes.reviews_from_other_firm', false);

        return (
            <section className={classes.container} id="reviews">
                <ProfileClientReviewsTitle profileData={profileData} isFirmReview = {false} />
                { hasReviewsFromOtherFirms && <ProfileClientReviewsLabel />}
                { this.props.children }
                {
                    loading
                    ? <LoadingSpinner className={classes.loadingWrapper} color="inherit" />
                    : (0 < profileReviewCount || reviewsTotalCount)
                        ? this.renderReviewList()
                        : <ProfileClientReviewsEmpty name={profileName} />
                }
            </section>
        );
    }
}

ProfileClientReviews.defaultProps = defaultProps;

export default withStyles(styles)(ProfileClientReviews);
