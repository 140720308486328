import { URL_COMPONENTS } from '../__constants__';

export function getRole(vertical_id, attribute_id) {
    switch(vertical_id) {
        case 5:
            return attribute_id === "392"
                ? 'Mortgage Advisers'
                : 'Financial Advisers and Mortgage Brokers';
        case 4:
            return 'Accountants';
        case 3:
            return 'Legal Advisers';
        default:
            return '';
    }
}

export function getSimpleRole(vertical_id) {
    switch(vertical_id) {
        case 5:
            return 'Financial and Mortgage Advisers';
        case 4:
            return 'Accountants';
        case 3:
            return 'Legal Advisers';
        default:
            return '';
    }
}

export function getRoleUrl(vertical_id) {
    switch(vertical_id) {
        case 5:
            return `/${URL_COMPONENTS.FA_MA_CANONICAL}`;
        case 4:
            return `/${URL_COMPONENTS.ACCOUNTANT}`;
        case 3:
            return `/${URL_COMPONENTS.SOLICITOR}`;
        default:
            return '';
    }
}

export function getRoleFromUrl(url) {
    switch(url) {
        case URL_COMPONENTS.FA_MA_CANONICAL:
            return 'Financial and Mortgage Advisers';
        case URL_COMPONENTS.ACCOUNTANT:
            return 'Accountants';
        case URL_COMPONENTS.SOLICITOR:
            return 'Solicitors';
        default:
            return '';
    }
}

