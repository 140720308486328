import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import CircularProgressGradient from './vectors/CircularProgressGradient';

import Hidden from '@material-ui/core/Hidden';
import withStyles from '@material-ui/core/styles/withStyles';

import { defaultPictureAvatar } from '../assets/images';
import _ from 'lodash';

const propTypes = {
    professionalName: PropTypes.string,
    xs: PropTypes.bool,
    inlineBlock: PropTypes.bool,
};

const defaultProps = {
    professionalName: 'Profile',
    xs: false,
    inlineBlock: false,
};

const styles = theme => ({
    personImage: {
        width: 160,
        height: 160,
        border: `1px solid white`,
        margin: `${theme.spacing(2)}px`,
        borderRadius: '50%',
        position: 'absolute',
        top: 0,
        left: 0,

        '&.xs': {
            width: 50,
            height: 50,
            margin: 10,
        },
        '&.md': {
            width: 84,
            height: 84,
            margin: 13,
        },
        '&.featuredAdvisersClass': {
            width: 130,
            height: 130,
        },
    },
    avatarWrap: {
        position: 'relative',
        margin: theme.spacing(1),
    },
    circularProgress: {
        width: 182,
        height: 182,
        margin: 6,

        '&.xs': {
            width: 60,
            height: 60,
        },
        '&.md': {
            width: 100,
            height: 100,
        },
        '&.featuredAdvisersClass': {
            width: 152,
            height: 152,
        },
    },
    inlineBlock: {
        display: 'inline-block',
    },
});

class ScoreAvatar extends Component {
    getProfessionalPhoto = (professional, size) => {
        const defaultPhoto = defaultPictureAvatar ;
        return _.get(professional, ['professional_photo', 'urls', size], defaultPhoto)
    }
    render() {
        const { classes, image, professional, ratingValue, ratingMax, inlineBlock, professionalName, xs, md, featuredAdvisersStyle } = this.props;
        const xsClass = xs ? 'xs' : '';
        const mdClass = md ? 'md' : '';
        const featuredAdvisersClass = featuredAdvisersStyle ? 'featuredAdvisersClass' : '';

        const smallUrl = professional ? this.getProfessionalPhoto(professional, 'small') : image;
        const standardUrl = professional ? this.getProfessionalPhoto(professional, 'standard') : image;

        return (
            <div className={cn({
                [classes.avatarWrap]: true,
                [classes.inlineBlock]: inlineBlock,
            })}>
                <CircularProgressGradient
                    rating={ratingValue}
                    maxRating={ratingMax}
                    className={cn(classes.circularProgress, xsClass, mdClass, featuredAdvisersClass)}
                />
                <Hidden smUp><img src={smallUrl} alt={professionalName} className={cn(classes.personImage, xsClass, mdClass, featuredAdvisersClass)}/></Hidden>
                <Hidden xsDown><img src={standardUrl} alt={professionalName} className={cn(classes.personImage, xsClass, mdClass, featuredAdvisersClass)}/></Hidden>
            </div>
        );
    }
}

ScoreAvatar.defaultProps = defaultProps;
ScoreAvatar.propTypes = propTypes;

export default withStyles(styles)(ScoreAvatar);
