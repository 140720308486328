import React from 'react';
import { makeStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import Button from '../CustomButton';
import Link from 'next/link'
import Skeleton from '../Skeleton';

const useStyles = makeStyles((theme) => ({
    titleWrapper: {
        position: 'relative',

        [theme.breakpoints.down('sm')]: {
            margin: `0 ${theme.spacing(2)}px`,
        },
    },
    sectionTitle: {
        color: theme.palette.primary.main,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(5),
        textAlign: 'center',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'left',
            fontSize: theme.spacing(3)
        }
    },
    leaveReviewButton: {
        position: 'absolute',
        top: -(theme.spacing(1) / 2) + 1,
        right: 0,
    },
    titleSkeleton: {
        margin: '0 auto',

        [theme.breakpoints.down('sm')]: {
            margin: 0,
        }
    },
    leaveReviewButtonSkeleton: {
        width: '126px !important',
    },
}));

const ProfileClientReviewsTitle = (props) => {
    const { loading, profileData, isFirmReview } = props;
    const classes = useStyles();

    return (
        <div className={classes.titleWrapper}>
            <div className={classes.sectionTitle}>
                {
                    loading
                        ? <Skeleton width={155} height={32} className={classes.titleSkeleton} />
                        : <Typography className={classes.sectionTitle}
                            variant="h3"
                            color="inherit"
                        >
                            Client reviews
                        </Typography>

                }
            </div>
            <div className={classes.leaveReviewButton}>
                {
                    profileData
                        ? <Link href={`/${profileData.permalink.url}/write-review`} passHref>
                            <Button
                                variant="contained"
                                color="tertiary"
                            >
                                Leave Review
                            </Button>
                        </Link>
                        : <>
                            {
                                loading &&
                                <Skeleton className={classes.leaveReviewButtonSkeleton} width={126} height={36} />
                            }
                        </>
                }
            </div>
        </div>
    );
};

export default ProfileClientReviewsTitle;
