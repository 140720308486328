import PropTypes from 'prop-types';
import { get } from 'lodash';
import Link from 'next/link'
import ScoreAvatar from '../ScoreAvatar';
import Button from '../Inputs/Button';
import PhoneIcon from '../vectors/PhoneIcon';
import EnvelopeIcon from '../vectors/EnvelopeIcon';

const propTypes = {
    showButtons: PropTypes.bool,
};

const defaultProps = {
    showButtons: false,
};

function ProfilePanel(props) {
    const { showButtons } = props;
    const data = get(props, ['data'], []);

    return (
        <div className='flex items-center max-sm:block max-sm:text-center max-sm:w-full'>
            <div className='grow-0 m-0 box-border sm:mr-2 sm:max-w-none sm:basis-auto xs:max-w-full xs:basis-full max-sm:flex max-sm:justify-center max-sm:items-center'>
                {data.c_is_contactable &&
                    <span className='sm:hidden'>
                        <a href={`tel:${data.phone_number}`} className='w-7 [&>svg]:w-7 h-7 p-1.5 flex items-center rounded-full content-center border-solid border-[3px] border-primary-blue [&>svg]:fill-primary-blue'>
                            <PhoneIcon />
                        </a>
                    </span>
                }

                <ScoreAvatar
                    ratingValue={data.review_average_score_overall}
                    ratingMax={5}
                    professional={data}
                    inlineBlock
                    professionalName={data.full_name}
                />

                {data.c_is_contactable &&
                    <span className='sm:hidden'>
                        <Link href={`/${data.permalink.url}/enquiry`} >
                            <a className='w-9 [&>svg]:w-9 h-9 p-1 flex items-center rounded-full content-center border-solid border-[3px] border-primary-blue [&>svg]:stroke-primary-blue' >
                                <EnvelopeIcon />
                            </a>
                        </Link>
                    </span>
                }
            </div>

            <div className='grow-0 sm:max-w-none sm:basis-auto xs:max-w-full xs:basis-full m-0 box-border'>
                <h1 className='text-[40px] font-normal leading-[1.5] m-0'>
                    {data.full_name}
                </h1>

                <h3 className='text-[1rem] font-normal leading-[1.75] m-0'>
                    {data.firm_url &&
                        <Link href={`/${data.firm_url}`} >
                            <a className='text-secondary-blue no-underline cursor-pointer'>{data.firm_name}</a>
                        </Link>
                    }

                    {!data.firm_url && data.firm_name}
                </h3>

                {showButtons &&
                    <div className='flex mt-2 flex-col max-xs:[&>a]:w-full max-sm:px-5'>
                        <div className='mb-1'>
                            <Link href={`/${data.permalink.url}`} passHref>
                                <Button
                                    bgColour='bg-mint-green hover:bg-mint-green-hover'
                                    className='w-full text-center'
                                    text='View full profile'
                                />
                            </Link>
                        </div>

                        {data.c_is_contactable &&
                            <Link href={`/${data.permalink.url}/enquiry`} passHref>
                                <Button
                                    className='w-full text-center'
                                    text={'Contact ' + data.first_name}
                                />
                            </Link>
                        }
                    </div>
                }
                    
                {!showButtons && data.wealth_level && 
                    <h4 className='text-[1rem] font-normal leading-[1.75] m-0'>
                        Minimum savings/pensions: {data.wealth_level}
                    </h4>
                }

                {!showButtons && data.mortgage_level && 
                    <h4 className='text-[1rem] font-normal leading-[1.75] m-0'>
                        Minimum mortgage: {data.mortgage_level}
                    </h4>
                }
            </div>
        </div>
    );
}

ProfilePanel.defaultProps = defaultProps;
ProfilePanel.propTypes = propTypes;

export default ProfilePanel;
